<script lang="ts" setup>
import { ModalsContainer } from 'vue-final-modal'

const navigationItems = [
  {
    title: 'Objednávky',
    subtitle: 'Přehled vašich objednávek',
    icon: 'i-heroicons-outline-clipboard-document-list',
    link: '/admin/orders',
  },
  {
    title: 'Údaje o firmě',
    icon: 'i-heroicons-outline-document-text',
    link: '/admin/company',
  },
  {
    title: 'Uživatelské účty',
    icon: 'i-heroicons-outline-identification',
    link: '/admin/users',
  },
  {
    title: 'Správa zákazníků',
    icon: 'i-heroicons-outline-user-group',
    link: '/admin/customers',
  },
  {
    title: 'Slevové hladiny',
    icon: 'i-heroicons-outline-cash',
    link: '/admin/sales',
  },
]

const router = useRouter()
const route = useRoute()

const backButtonText = computed(() => {
  if (route.fullPath.includes('/customers/detail') && route.fullPath.includes('orders'))
    return 'Zpět'
  if (route.fullPath.includes('/detail'))
    return 'Zpět na seznam'
  return 'Zpět do konfigurátoru'
})
</script>

<template>
  <div flex flex-col justify-between min-h-full>
    <div>
      <Header />
      <div flex items-start gap-40px mt-32px pl-32px>
        <div w-320px>
          <div flex items-center gap-12px cursor-pointer @click="router.back()">
            <UnoIcon i-heroicons-solid-arrow-left text="20px black" />
            <h1 text="14px black" leading-20px font-500>
              {{ backButtonText }}
            </h1>
          </div>
          <div mt-32px w-full rounded-12px border>
            <NuxtLink v-for="item in navigationItems" :key="item.link" :to="item.link" flex items-center gap-16px rounded-12px p-20px h-64px focus:outline-none :class="{ 'bg-app-blue': route.fullPath.includes(item.link) }">
              <UnoIcon :class="[route.fullPath.includes(item.link) ? 'text-white' : 'text-black', item.icon]" text-24px />
              <div>
                <h1 :class="{ 'text-white': route.fullPath.includes(item.link) }" text="14px" leading-20px font-400>
                  {{ item.title }}
                </h1>
                <h2 v-if="item.subtitle" :class="{ 'text-app-text-white': route.fullPath.includes(item.link) }" text="12px app-text-black" leading-16px font-500>
                  {{ item.subtitle }}
                </h2>
              </div>
            </NuxtLink>
          </div>
        </div>
        <div w-full pr-32px>
          <NuxtPage />
        </div>
      </div>
    </div>
    <ModalsContainer />
  </div>
</template>
